@tailwind base;

@layer base {
  h1,
  .h1 {
    @apply text-40 font-light tracking-0;
  }

  h2,
  .h2 {
    @apply text-36 font-light tracking-0;
  }

  h3,
  .h3 {
    @apply text-30 font-light tracking-0;
  }

  h4,
  .h4 {
    @apply text-27 font-light tracking-0;
  }

  @media screen(lg) {
    h1,
    .h1 {
      @apply text-60;
    }

    h2,
    .h2 {
      @apply text-50;
    }

    h3,
    .h3 {
      @apply text-40;
    }

    h4,
    .h4 {
      @apply text-30;
    }
  }

  body {
    color: theme('colors.black');

    --background-color: theme('colors.white');

    @apply text-20 tracking-20 leading-30 font-light break-words;

    word-break: break-word;
    width: 100%;
  }

  code {
    font-size: 0.85em;
    background-color: #faf8f5;
    font-family:
      Consolas, Menlo, Monaco, 'Andale Mono WT', 'Andale Mono',
      'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono',
      'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
      'Courier New', Courier, monospace;
  }
}

@tailwind components;

@tailwind utilities;

@layer utilities {
  .bg-black,
  .background-black {
    --background-color: theme('colors.black');
  }

  .bg-violet,
  .background-violet {
    --background-color: theme('colors.violet');
  }

  .bg-yellow,
  .background-yellow {
    --background-color: theme('colors.yellow');
  }

  .bg-blue,
  .background-blue {
    --background-color: theme('colors.blue');
  }

  .bg-white,
  .background-white {
    --background-color: theme('colors.white');
  }

  .bg-orange,
  .background-orange {
    --background-color: theme('colors.orange');
  }

  .bg-petrol,
  .background-petrol {
    --background-color: theme('colors.petrol');
  }

  .bg-red,
  .background-red {
    --background-color: theme('colors.red');
  }

  .background-highlight {
    --background-color: var(--highlight-color);
  }

  .highlight-background {
    --highlight-color: var(--background-color);
  }

  .highlight-black {
    --highlight-color: theme('colors.black');
  }

  .highlight-violet {
    --highlight-color: theme('colors.violet');
  }

  .highlight-yellow {
    --highlight-color: theme('colors.yellow');
  }

  .highlight-blue {
    --highlight-color: theme('colors.blue');
  }

  .highlight-white {
    --highlight-color: theme('colors.white');
  }

  .highlight-orange {
    --highlight-color: theme('colors.orange');
  }

  .highlight-petrol {
    --highlight-color: theme('colors.petrol');
  }

  .highlight-red {
    --highlight-color: theme('colors.red');
  }

  .iubenda-markup h1,
  .iubenda-markup h2,
  .iubenda-markup h3,
  .iubenda-markup h4,
  .iubenda-markup h5,
  .iubenda-markup h6 {
    @apply mt-32 mb-8;
  }
}

@layer components {
  .anchor {
    position: relative;
    cursor: pointer;

    /* Animation of underlining */
    @apply bg-current;

    background:
      linear-gradient(to right, currentcolor, currentcolor),
      linear-gradient(to right, transparent, transparent),
      linear-gradient(to right, currentcolor, currentcolor);
    background-size:
      100% 1px,
      0% 1px,
      0% 1px;
    background-position:
      100% 100%,
      0 100%,
      0 100%;
    background-repeat: no-repeat;
  }

  .anchor:hover {
    animation-name: underlining;
    animation-duration: 700ms;
    animation-delay: 0s;
    animation-fill-mode: both;
  }

  @keyframes underlining {
    50% {
      background-size:
        0% 1px,
        100% 1px,
        0% 1px;
    }

    100% {
      background-size:
        0% 1px,
        0% 1px,
        100% 1px;
    }
  }
}
